


































































import { AnonymousInvestorPlatformClient } from '@/client'
import { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Register extends Vue {
  email: string = ''
  password: string = ''
  registerType: number = 0
  error: string = ''

  async register() {
    const client = new AnonymousInvestorPlatformClient(process.env.VUE_APP_API_ENDPOINT!)
    const registerType = this.registerType
    const isInvestor = registerType == 1
    try {
      await client.register(this.email, this.password, isInvestor)

      if (registerType == 0) {
        await this.$router.push('/register-done-trader')
      } else {
        await this.$router.push('/register-done')
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status == 409) {
          this.error = 'User already exists'
        }
      }

      throw e
    }
  }
}
